const keys = [
  'due_date_days',
  'recurrence_day_of_month',
  'recurrence_days_of_week',
  'recurrence_enabled',
  'recurrence_every',
  'recurrence_period',
  'recurrence_type',
  'recurrence_until_date',
  'recurrence_until_repeats'
]


// due_date_days: obj.due_date_days,
// recurrence_day_of_month: obj.recurrence_day_of_month,
// recurrence_days_of_week: obj.recurrence_days_of_week,
// recurrence_enabled: obj.recurrence_enabled,
// recurrence_every: obj.recurrence_every,
// recurrence_period: obj.recurrence_period,
// recurrence_type: obj.recurrence_type,
// recurrence_until_date: obj.recurrence_until_date,
// recurrence_until_repeats: obj.recurrence_until_repeats


function getRecurrenceData(data) {
  if (!data) return null

  const settings = {}

  keys.forEach(key => {
    settings[key] = data[key]
  })

  return settings
}

export default getRecurrenceData
